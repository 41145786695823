@use 'styles/abstracts' as *;

.municipalities {
  display: flex;
  .comuni {
    padding-left: 1.25rem;
    border-left: 1px solid $col-primary-60;
    margin-left: 1.25rem;
    height: 100%;
    :global(.MuiInputBase-root) {
      width: 16rem;
      height: 2.5rem;
    }
  }
}

