@use 'styles/abstracts' as *;

.modal {
  width: 25rem;
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'text text'
    'buttons buttons';
  gap: 1.5rem;
  span {
    grid-area: text;
  }
  div {
    grid-area: buttons;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.875rem;
    button {
      width: 10.625rem;
      height: 2.5rem;
      border-radius: 0.5rem;
      box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.12);
    }
    .cancelButton {
      background-color: transparent;
      color: $col-primary-90;
    }
    .confirmButton {
      background-color: $col-error-60;
      color: $col-white;
    }
  }
}

