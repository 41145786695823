@use 'styles/abstracts' as *;

.chipsFilterContainer {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  label {
    margin-left: 0.5rem;
    color: $col-primary-100;
  }
  .mappedChips {
    display: flex;
    gap: 0.5rem;
    .chip {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2rem;
      padding: 0rem 1rem;
      border-radius: 1.75rem;
      border: 1px solid $col-primary-60;
      background-color: $col-primary-10;
      color: $col-primary-100;
      cursor: pointer;
    }
    .chip:hover {
      background-color: $col-primary-20;
    }
    .chip[data-selected='true'] {
      background-color: $col-primary-60;
    }
  }
}

