@use 'styles/abstracts' as *;

// TODO preparare reset
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  position: fixed;
  width: 0.5rem;
  height: 0.5rem;
  background-color: $col-primary-10;
}
::-webkit-scrollbar-thumb {
  background: $col-primary-40;
  border-radius: 0.125rem;
}
