div.mapboxgl-ctrl-group:has(button.mapboxgl-ctrl-geolocate) {
  display: none !important;
}

div#view-default-view {
  height: 100vh !important;
}

.mapboxgl-popup-content {
  background: rgba(2, 20, 42, 1);
  border-radius: 0.75rem;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: rgba(2, 20, 42, 1);
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: rgba(2, 20, 42, 1);
}
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: rgba(2, 20, 42, 1);
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: rgba(2, 20, 42, 1);
}

