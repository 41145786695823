@use 'styles/abstracts' as *;

.spinner {
  display: flex;
  width: fit-content;
  height: fit-content;
  animation: spin 1s linear infinite;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}

