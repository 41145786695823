@use 'styles/abstracts' as *;

.cityFilterContainer {
  label {
    margin-left: 0.5rem;
    color: $col-primary-100;
  }
  :global(.MuiInputBase-root) {
    margin-top: 0.25rem;
    border-radius: 0.5rem;
    border: 1px solid $col-primary-60;
    height: 2.25rem;
    display: flex;
    align-items: center;
    padding: 0 !important;
  }
  :global(.MuiAutocomplete-input) {
    color: $col-white;
    display: flex;
    align-items: center;
    padding: 0 0 0 1rem !important;
  }
  :global(.MuiAutocomplete-popupIndicator) {
    display: none;
  }
  :global(.MuiAutocomplete-endAdornment) {
    top: initial;
  }
  :global(.MuiOutlinedInput-root) {
    font-family: $titillium;
  }
  :global(.MuiOutlinedInput-notchedOutline) {
    border: 0 !important;
  }
  :global(.MuiAutocomplete-noOptions) {
    background-color: $col-primary-10 !important;
    font-family: $titillium !important;
    color: $col-white;
  }
  input[type='text']:focus {
    border: none !important;
    font-family: $titillium;
  }
}
