@use 'styles/abstracts' as *;

.container {
  width: 100%;
  display: flex;
  flex-flow: column;
  position: relative;
  .create {
    position: absolute;
    right: 0rem;
    top: 0.5rem;
    z-index: 1;
    margin-right: 1.5rem;
  }
  .icon {
    display: none;
  }
  :global(.MuiDataGrid-row):hover {
    .icon {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      button {
        margin: 0 0 0 1rem;
      }
    }
  }
}
