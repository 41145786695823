@use 'styles/abstracts' as *;

.sidebar {
  position: relative;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  padding: 0.625rem;
  border-right: 0.0625rem solid $col-primary-60;
  background-color: $col-primary-0;
}

.logout {
  position: absolute;
  bottom: 0.625rem;
}
