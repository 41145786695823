@use 'styles/abstracts' as *;

.container {
  width: 100%;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../../public/login.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.form {
  max-width: 19rem;
  min-height: 20.25rem;
  padding: 1.5rem;
  background-color: $col-primary-10;
  border-radius: 0.75rem;
  border: 0.0625rem solid $col-primary-60;
  display: flex;
  flex-flow: column;
  gap: 1.3125rem;
  p {
    color: $col-white;
  }
  button {
    display: flex;
    width: 100%;
  }
  ul {
    padding-left: 1.5rem;
  }
}
