@use 'styles/abstracts' as *;

.container {
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
}

.header {
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.tariffContainer {
  height: auto;
  left: 84px;
  width: 100%;
  top: 302px;
  border-radius: 12px;
  background-color: $col-primary-10;
  height: fit-content;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
