@use 'styles/abstracts' as *;

.iconContainer {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .counter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: $col-primary-60;
    position: relative;
    right: 0.25rem;
    top: -0.375rem;
    font-size: 0.625rem;
  }
}

