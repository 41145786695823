@use 'styles/abstracts' as *;

.field {
  width: 33%;
  display: flex;
  flex-flow: row;
  gap: 1rem;
  label {
    margin-left: 0.5rem;
    color: $col-primary-100;
  }
  .error {
    border: 1px solid #fb9aa0;
  }
  input[type='time'] {
    font-family: $titillium;
    &:hover {
      background-color: $col-primary-10;
    }
    &:focus {
      border-color: $col-primary-90;
    }
    &:disabled {
      border-color: $col-primary-40 !important;
      color: $col-primary-40 !important;
    }
  }
}
