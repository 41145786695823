@use 'styles/abstracts' as *;

.optionsList {
  background-color: $col-primary-20;
  border: $col-primary-60 1px solid;
  padding: 2px;
  border-top: none;
  border-radius: 0.5rem;
  :global(.MuiChip-label) {
    background-color: $col-primary-40;
    border-radius: 4px;
    font-family: $titillium;
    font-size: 12px;
    font-family: 400;
    color: $col-primary-90;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  :global(.MuiAutocomplete-listbox) {
    background-color: $col-primary-0 !important;
    border-radius: 0.5rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .chips {
    max-height: 6.25rem;
    overflow: auto;
  }
  .capitalGroup {
    background-color: $col-primary-0 !important;
    padding-left: 1rem;
    padding: 0.3125rem;
    height: 2.125rem;
  }
  :global(.Mui-focused) {
    background-color: $col-primary-0 !important;
  }
  .list {
    background-color: $col-primary-10 !important;
    padding: 0;
    .checkBoxStyle {
      margin-right: 2px;
    }
  }
  .list:hover {
    background-color: $col-primary-20 !important;
  }
}

.autocomplete {
  :global(.MuiAutocomplete-inputRoot) {
    margin: 0 !important;
    padding: 0 !important;
  }
  :global(.MuiInputBase-root) {
    height: 2.25rem;
    border-radius: 0.5rem;
    color: $col-white !important;
    font-family: $titillium;
    background-color: $col-primary-0;
    &:hover {
      background-color: $col-primary-10;
    }
  }
  :global(.MuiOutlinedInput-notchedOutline) {
    border-style: none;
  }
  :global(.MuiInputBase-root.Mui-focused) {
    border-color: $col-primary-60 !important;
  }
  :global(.MuiInputBase-input) {
    opacity: 1;
    background-color: transparent;
    border: none;
    padding: 0 1rem;
    &::placeholder {
      font-size: 1rem;
      color: $col-primary-60 !important;
      font-family: $titillium;
    }
  }
  :global(.MuiAutocomplete-input) {
    margin: 0 !important;
    padding: 0 !important;
    padding-left: 0.5rem !important;
  }
  :global(.MuiAutocomplete-endAdornment) {
    top: 0;
    svg {
      width: 0.8rem;
    }
  }
  :global(.MuiAutocomplete-clearIndicator) {
    margin-right: 0.5rem;
    path {
      fill: $col-primary-60;
    }
  }
  :global(.MuiAutocomplete-endAdornment) {
    height: 2.25rem;
    button {
      height: 2.25rem;
    }
  }
  .chipDefault input {
    border: none !important;
  }
  .search {
    svg {
      margin: 0.375rem 0 0 0.5rem;
    }
  }
  p {
    color: $col-primary-100;
  }
}

