@use 'styles/abstracts' as *;

.vehiclesContainer {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  p {
    color: $col-primary-100;
    margin-bottom: 0.5rem;
  }
  label {
    margin-left: 0.5rem;
    color: $col-primary-100;
  }
  :global(.MuiFormControl-root) {
    height: 2.25rem;
    width: 21rem;
    border-radius: 0.5rem;
  }
  :global(.MuiInputBase-root) {
    height: 2.25rem;
    border-radius: 0.5rem;
    border: 1px solid $col-primary-60;
    color: $col-white;
    font-family: $titillium;
    background-color: $col-primary-0;
    svg {
      fill: $col-primary-60 !important;
    }
    &:hover {
      background-color: $col-primary-10;
    }
  }
  :global(.MuiOutlinedInput-notchedOutline) {
    border-style: none;
  }
  :global(.MuiSelect-nativeInput) {
    opacity: 1;
    color: transparent !important;
    height: 2.25rem;
    background-color: transparent;
    border: none;
    padding: 0 0.5rem;
    &::placeholder {
      font-size: 1rem;
      color: $col-primary-60 !important;
      font-family: $titillium;
    }
  }
  :global(.MuiTypography-root) {
    color: $col-white;
  }
  :global(.Mui-disabled) {
    -webkit-text-fill-color: $col-primary-40 !important;
    border-color: $col-primary-40 !important;
    :global(.MuiSelect-nativeInput) {
      opacity: 0;
    }
  }
}
:global(div.MuiMenu-paper) {
  background-color: $col-primary-0;
  color: $col-primary-60 !important;
  border: 1px solid $col-primary-40;
  border-radius: 0.5rem;
  :global(.MuiButtonBase-root, .MuiCheckbox-root) {
    color: $col-primary-60 !important;
  }

  :global(li.MuiMenuItem-root:hover) {
    background-color: $col-primary-10;
  }
}
