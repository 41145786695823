@use 'styles/abstracts' as *;

.filtersTab {
  width: 100%;
  margin-top: 1.25rem;
  .subtitle {
    color: $col-primary-100;
    margin-left: 2.25rem;
  }
  .topBar {
    display: grid;
    grid-template-columns: auto auto;
    margin-left: 2.25rem;
    .title {
      display: flex;
      align-items: center;
      .closeBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.5rem;
        width: 2.5rem;
        cursor: pointer;
      }
    }
    button {
      justify-self: end;
    }
  }
  .dateFilter {
    display: flex;
    gap: 1rem;
    margin-left: 2.25rem;
  }
  .inputTextGroup {
    display: flex;
    gap: 10rem;
    margin-left: 2.25rem;
  }
}
