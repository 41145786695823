@use 'styles/abstracts' as *;

.fieldOrganizerAccordion {
  padding: 0px !important;
  margin: 0px !important;
  :global(.MuiAccordionDetails-root) {
    padding: 0 !important;
  }
  .subtitle {
    color: $col-primary-100 !important;
  }
}

.fieldOrganizerTypography {
  padding: 0px !important;
  margin: 0px !important;
  width: 100% !important;
  padding-bottom: 0.75rem !important;
  :global(.MuiAccordionDetails-root) {
    padding: 0 !important;
  }
  :global(.MuiFormGroup-root) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    padding-left: 0.8rem;
    row-gap: 0.5rem;
  }
  :global(.MuiFormControlLabel-label) {
    padding-left: 0.5rem;
  }
  :global(.MuiFormControlLabel-root) {
    width: fit-content;
  }
  :global(.MuiTypography-root) {
    font-size: 1rem;
    color: $col-primary-100;
  }
  .error {
    border: 1px solid #fb9aa0;
    border-radius: 5px;
    margin: -1px;
  }
}

