@use '../abstracts' as *;

button {
  cursor: pointer;
  border: none;
  outline: none;
  transition: all 0.3s ease;
  height: 2.5rem;
  font-family: $titillium;
  font-size: 1rem;
  font-weight: 600;
  overflow: hidden;
  &.primary {
    background-color: $col-secondary-60;
    color: $col-primary-0;
    svg {
      fill: $col-primary-0;
    }
    &:hover {
      background-color: $col-secondary-80;
    }
    &:active {
      color: $col-primary-40;
      svg {
        fill: $col-primary-40;
      }
    }
    &:disabled {
      background-color: $col-primary-10;
      color: $col-primary-40;
      svg {
        fill: $col-primary-40;
      }
    }
  }
  &.secondary {
    background-color: $col-primary-10;
    color: $col-primary-100;
    svg {
      fill: $col-primary-100;
    }
    &:hover {
      background-color: $col-primary-20;
    }
    &:active {
      color: $col-primary-80;
      svg {
        fill: $col-primary-80;
      }
    }
    &:disabled {
      background-color: $col-primary-10;
      color: $col-primary-40;
      svg {
        fill: $col-primary-40 !important;
      }
    }
  }
  &.secondaryVariant {
    background-color: $col-primary-20;
    color: $col-primary-100;
    svg {
      fill: $col-primary-100;
    }
    &:hover {
      background-color: $col-primary-40;
    }
    &:active {
      background-color: $col-primary-20;
      color: $col-primary-80;
      svg {
        fill: $col-primary-80;
      }
    }
    &:disabled {
      background-color: $col-primary-10;
      color: $col-primary-40;
      svg {
        fill: $col-primary-40;
      }
    }
  }
  &.tertiary {
    background-color: transparent;
    color: $col-primary-90;
    svg {
      fill: $col-primary-90;
    }
    &:hover {
      color: $col-primary-80;
      svg {
        fill: $col-primary-80;
      }
    }
    &:active {
      color: $col-primary-100;
      svg {
        fill: $col-primary-100;
      }
    }
    &:disabled {
      color: $col-primary-40;
      svg {
        fill: $col-primary-40;
      }
    }
  }
  &.error {
    background-color: $col-error-60;
    color: $col-white;
    &:hover {
      background-color: $col-error-40;
    }
  }
  &.text {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 0.25rem;
    }
  }
  &.icon {
    width: 2.5rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
