@use 'styles/abstracts' as *;

.filtersContainer {
  height: calc(100%);
  overflow: hidden;
  .dashboard {
    padding: 0 1.5rem 1rem 1.5rem;
    background-color: $col-primary-10;
    border-bottom: 1px solid $col-primary-60;
  }
  .filterWrapper {
    padding: 0 1.5rem 0.5rem;
    p {
      color: $col-primary-100;
    }
    .header {
      padding-top: 1.4rem;
      display: grid;
      grid-template-columns: 1fr auto auto;
      align-items: center;
      justify-content: space-between;
      .title {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  .dateFilter {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    margin-top: 0.5rem;
  }
  .filters {
    padding: 0 1.5rem 1rem;
    height: calc(100% - 200px);
    overflow: auto;
    p {
      margin-bottom: 0.5rem;
    }
    .divider {
      margin-bottom: 1rem;
    }
    .lastDivider {
      margin-top: 0.5rem;
    }
  }
}

.inputStyle {
  :global(.MuiSelect-nativeInput) {
    display: none;
  }
  :global(
      .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input
    ) {
    font-family: $titillium;
  }
}

