@use 'styles/abstracts' as *;

.field {
  word-wrap: break-word;
}

.fieldButton {
  margin: 1rem;
}

.billingInfo {
  width: 125px;
  height: 30px;
  border-radius: 4px;
  background-color: $col-primary-20;
  display: flex;
  align-items: center;
  color: $col-white;
  font-weight: 400;
  font-size: 12px;
  label {
    padding: 0;
  }
}

.accordionContainer {
  padding: 0 0.5rem;
  :global(.MuiButtonBase-root) {
    padding-left: 0.5rem !important;
  }
  :global(.MuiAccordionDetails-root) {
    padding: 0 16px 0 !important;
  }
}

