.action {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  .back,
  .buttons {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

