@use 'styles/abstracts' as *;

.modalArea {
  width: 26rem;
  .motivation {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    label {
      margin-left: 0.5rem;
    }
    textarea {
      width: 100%;
      height: 5rem;
      background-color: transparent;
      border: 1px solid $col-primary-60;
      border-radius: 8px;
      color: white;
      padding: 0.5rem;
      font-family: $titillium;
      font-size: 1rem;
      line-height: 1.3125rem;
      &::placeholder {
        color: $col-primary-60;
      }
      &:focus-visible {
        outline: none;
      }
    }
  }
  .dateError {
    color: #fb9aa0;
  }
  .buttons {
    display: flex;
    button {
      width: 50%;
      margin-top: 0.5rem;
    }
  }
  .schedule {
    margin: 1rem 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    row-gap: 1rem;
    label {
      margin: 0 1rem 0 0.5rem;
      color: $col-primary-100;
    }
    .datePicker,
    .react-datepicker__input-container,
    input {
      border-radius: 0.5rem;
      border: 1px solid $col-primary-60;
      height: 2.25rem;
      width: 9.6875rem;
      color: $col-white;
      font-size: 1rem;
      font-weight: 400;
      padding: 1rem;
      background-color: transparent;
      &:focus {
        border-color: $col-primary-90;
      }
      &:hover {
        background-color: $col-primary-10;
      }
    }
    ::placeholder {
      color: $col-primary-60 !important;
      font-family: $titillium;
    }
    & textarea:focus,
    input:focus {
      outline: none;
    }
    .datePickerContainer {
      display: grid;
      align-items: center;
      grid-template-columns: auto auto auto;
      svg {
        position: relative;
        right: 25px;
        pointer-events: none;
      }
    }
    .dateError {
      border: 1px solid $col-error-90 !important;
    }
    .field {
      width: 33%;
      display: flex;
      flex-flow: row;
      gap: 1rem;
      label {
        margin-left: 0.5rem;
        color: $col-primary-100;
      }
      .error {
        border: 1px solid #fb9aa0;
      }
      input[type='time'] {
        font-family: $titillium;
        &:hover {
          background-color: $col-primary-10;
        }
        &:focus {
          border-color: $col-primary-90;
        }
        &:disabled {
          border-color: $col-primary-40 !important;
          color: $col-primary-40 !important;
        }
      }
    }
  }
}

