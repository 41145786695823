@use 'styles/abstracts' as *;

.paymentContainer {
  width: fit-content;
  .title {
    color: $col-primary-100;
    margin-bottom: 0.375rem;
  }
  .filterContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 1.5rem;
  }
}

