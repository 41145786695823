@use 'styles/abstracts' as *;

.modalContainer {
  width: 18.75rem;
  .subWrapper {
    display: grid;
    grid-template-columns: 1fr;
    .checkboxWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .text {
      margin-left: 2.5rem;
    }
  }
  .yearWrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    padding-left: 2.25rem;
    margin-top: 1rem;
    .datePickerContainer {
      .datePicker,
      .react-datepicker__input-container,
      input {
        border-radius: 0.5rem;
        border: 1px solid $col-primary-60;
        height: 2.25rem;
        width: 9rem;
        color: $col-white;
        font-size: 1rem;
        font-weight: 400;
        padding: 1rem;
        background-color: transparent;
        &:disabled {
          background-color: $col-primary-10;
          color: $col-primary-40;
        }
        &:focus {
          border-color: $col-primary-90;
        }
        &:hover {
          background-color: $col-primary-10;
        }
      }
      display: grid;
      align-items: center;
      grid-template-columns: auto auto auto;
      svg {
        position: relative;
        right: 25px;
        pointer-events: none;
      }
    }
  }
  .btn {
    display: flex;
    gap: 0.625rem;
    margin-top: 1.5rem;
    .customButton {
      width: 7.5rem;
    }
  }
}

