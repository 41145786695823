@use 'styles/abstracts' as *;

.container {
  width: 50%;
  overflow: hidden;
  height: 100dvh;
  background-color: $col-primary-0;
  z-index: 2;
  min-width: 48rem;
  .toggle {
    background-color: $col-primary-10;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }
  .tables {
    position: relative;
    height: calc(100% - 100px);
    background-color: $col-primary-0;
    color: $col-white;
  }
}
.containerWidth {
  width: 100%;
}
