@use '../abstracts' as *;

select {
  border: none;
  padding: $size-0;
  font-family: $titillium;
  border-radius: $size-1;
  font-weight: 400;
  outline: none;
}
