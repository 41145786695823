@use 'styles/abstracts' as *;

.container {
  width: 100%;
  height: 100dvh;
  overflow: auto;
  background-color: $col-primary-0;
  padding: 1.25rem 0;
  .tables {
    background-color: $col-primary-0;
  }
  .report {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    svg {
      height: 4.6875rem;
      width: 4.6875rem;
      path {
        color: $col-primary-60;
      }
    }
    p {
      text-align: center;
    }
  }
}

