@use 'styles/abstracts' as *;

.field {
  display: flex;
  flex-flow: column;
  gap: 1rem;
  padding-right: 0.5rem;
  .customerId,
  .email {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }
}

.accordionContainer {
  padding: 0 0.5rem;
  :global(.MuiButtonBase-root) {
    padding-left: 0.5rem !important;
  }
  :global(.MuiAccordionDetails-root) {
    padding: 0 16px 0 !important;
  }
}

