@use 'styles/abstracts' as *;
.container {
  height: calc(100vh - 100px);
  overflow: auto;
  .arrow {
    display: none;
  }
  :global(.MuiDataGrid-row):hover {
    .arrow {
      display: inline;
      position: absolute;
      right: 0;
      path {
        fill: $col-primary-90;
      }
    }
  }
  :global(.MuiDataGrid-row) {
    cursor: pointer;
  }
  .totalAmount {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
}
.filterButton {
  position: absolute;
  z-index: 1;
  right: 4rem;
  top: 7.1875rem;
  .active {
    position: absolute;
    top: -0.25rem;
    right: 0.4rem;
  }
}

.dashboard {
  padding: 0 1.5rem;
  background-color: $col-primary-10;
}

.canceled {
  box-shadow: 0 0 0 1px inset $col-error-90;
}

.fullScreen {
  position: absolute;
  z-index: 1;
  right: 1.5rem;
  top: 7.25rem;
}

