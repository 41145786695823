@use 'styles/abstracts' as *;

.container {
  padding: 0.875rem 1rem;
  background-color: $col-primary-0;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    span {
      font-size: 0.75rem;
    }
  }
}
