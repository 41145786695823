@use 'styles/abstracts' as *;

form {
  width: 100%;
  height: 100%;
  font-family: $titillium;
  display: flex;
  flex-direction: column;
}

.formInput {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input {
    border: 1px solid $col-primary-60;
    color: $col-white;
    font-family: $titillium;
    outline: none;
    height: 2.25rem;
    background-color: $col-primary-0;
    border-radius: 0.5rem;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    width: 100%;
    &::placeholder {
      color: $col-primary-60 !important;
    }
    &:hover {
      background-color: $col-primary-10;
    }
    &:focus {
      border-color: $col-primary-90;
    }
  }
}

.field,
.municipality {
  display: flex;
  flex-flow: column;
  font-family: $titillium;
  position: relative;
  margin-bottom: 0.75rem;
  :global(.MuiAutocomplete-root) {
    width: 256px;
    svg {
      margin-left: 0.3rem;
      width: 17px;
    }
  }
  label {
    color: $col-primary-100;
    margin-bottom: 0.25rem;
  }
  .error {
    border: 1px solid $col-error-90 !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $col-primary-10 inset !important;
  -webkit-text-fill-color: $col-primary-100 !important;
}

.buttons {
  display: flex;
  margin-top: 1.25rem;
  gap: $size-2;
  justify-content: flex-end;
  width: 100%;
  justify-content: center;
  button {
    width: 35%;
  }
}

.containerFields {
  display: flex;
  .alertPassword {
    display: flex;
    align-items: center;
  }
  .fields {
    width: 100%;
  }
}

:global(.PhoneInputCountry) {
  border-radius: 0.5rem;
  padding: 0 0.5rem;
  border: 1px solid $col-primary-60;
  background-color: $col-primary-0;
  display: flex;
  gap: 0.5rem;
  &:hover {
    background-color: $col-primary-10;
  }
  :global(.PhoneInputCountrySelectArrow) {
    transform: rotate(45deg) translate(-1px, -1px);
    transition: all 0.2s ease;
  }
  :global(.PhoneInputCountrySelect:focus)
    + :global(.PhoneInputCountryIcon)
    + :global(.PhoneInputCountrySelectArrow) {
    transform: rotate(225deg) translate(-1px, -1px);
  }
  :global(.PhoneInputCountrySelect:focus) {
    background-color: $col-primary-10;
    border-radius: 0.5rem !important;
    option {
      color: white;
      font-family: $titillium;
      font-size: 1rem;
    }
  }
  :global(.PhoneInputCountrySelectArrow) {
    transform: rotate(45deg) translate(-1px, -1px);
    transition: all 0.2s ease;
  }
  :global(.PhoneInputCountrySelect:focus)
    + :global(.PhoneInputCountryIcon)
    + :global(.PhoneInputCountrySelectArrow) {
    transform: rotate(225deg) translate(-1px, -1px);
  }
  :global(.PhoneInputCountrySelect:focus) {
    background-color: $col-primary-10;
    border-radius: 0.5rem !important;
    option {
      color: white;
      font-family: $titillium;
      font-size: 1rem;
    }
  }
}

:root {
  --PhoneInput-color--focus: transparent;
  --PhoneInputCountrySelect-marginRight: 0.5rem;
  --PhoneInputCountryFlag-height: 1rem;
  --PhoneInputCountrySelectArrow-color: #1072e7;
  --PhoneInputCountrySelectArrow-opacity: 1;
}

.phoneInput {
  input {
    border-color: #fb9aa0;
  }
}

