@use 'styles/abstracts' as *;

.modalArea {
  width: 25rem;
  .buttons {
    display: flex;
    button {
      width: 50%;
      margin-top: 0.5rem;
    }
  }
}

