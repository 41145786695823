@use 'styles/abstracts' as *;

.header {
  display: flex;
  border-bottom: 0.0625rem solid $col-primary-60;
  padding: $size-2 $size-3;
  height: 5rem;
  border-bottom: 0.0625rem solid $col-primary-60;
  justify-content: space-between;
  .back {
    display: flex;
    align-items: center;
    span {
      color: $col-primary-60;
    }
  }
}

.form {
  height: calc(100% - 5rem);
  display: flex;
  flex-direction: column;
  gap: $size-0;
  padding-right: $size-1;
  padding-top: $size-1;
  margin: 0 $size-1 0 $size-2;
  width: revert-layer;
  overflow: auto;
  .info {
    display: flex;
    justify-content: space-between;
    .schedule {
      display: flex;
      align-items: center;
      gap: $size-3;
      :global(.MuiFormControlLabel-root) {
        flex-direction: row-reverse;
        margin: 0;
      }
      :global(.MuiSwitch-root) {
        margin: 0;
        rotate: 180deg;
      }
      b {
        margin-right: 0.3rem;
      }
    }
  }
  .row {
    display: flex;
    gap: $size-1;
    .field {
      display: flex;
      width: 50%;
      flex-flow: column;
      font-family: $titillium;
      position: relative;
      .disabled {
        color: $col-primary-40;
      }
      input {
        border: 1px solid $col-primary-60;
        color: $col-primary-100;
        outline: none;
        height: 2.25rem;
        background-color: transparent;
        border-radius: 0.5rem;
        padding: $size-1 $size-2;
        margin-bottom: 0.75rem;
        font-family: $titillium;
        &::placeholder {
          color: $col-primary-60 !important;
        }
        &:focus {
          border-color: $col-primary-90;
        }
        &:hover {
          background-color: $col-primary-10;
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type='number'] {
        -moz-appearance: textfield;
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $col-primary-10 inset !important;
        -webkit-text-fill-color: $col-primary-100 !important;
      }
      &.noDisplay {
        visibility: hidden;
      }
    }
  }
  .tariff {
    display: flex;
    flex-direction: column;
    select {
      background-color: transparent;
      border: 1px solid $col-primary-60;
      color: #ffffff;
      font-size: 1rem;
      option {
        background-color: transparent;
        color: #ffffff;
      }
    }
  }
  label {
    color: $col-primary-100;
    margin-bottom: 0.25rem;
  }
}

.createBtn {
  position: absolute;
  right: $size-3;
  bottom: $size-3;
}

.alignInput {
  width: calc(100% - 0.5rem);
}

