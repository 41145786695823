@use 'styles/abstracts' as *;

.container {
  height: 100%;
  width: 34.375rem;
  border-right: 1px solid $col-primary-60;
  background-color: $col-primary-0;
  .header {
    height: 6.25rem;
    width: 100%;
    padding: 1.25rem 1.5rem 0.5rem 1.5rem;
    border-bottom: 1px solid $col-primary-60;
    p {
      color: $col-primary-100;
    }
    .filters {
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: space-between;
      width: 100%;
      .filterButton {
        position: relative;
        .active {
          position: absolute;
          top: -0.25rem;
          right: 0.4rem;
        }
      }
    }
  }
  .items {
    width: 100%;
    overflow: auto;
    height: calc(100% - 6.25rem);
    padding: 0.25rem 1.5rem;
  }
}

