@use 'styles/abstracts' as *;

.container {
  width: 100%;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../../public/login.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.form {
  width: 19rem;
  min-height: fit-content;
  padding: 1.5rem;
  background-color: $col-primary-10;
  border-radius: 0.75rem;
  border: 0.0625rem solid $col-primary-60;
  display: flex;
  flex-flow: column;
  gap: 1.3125rem;
  input {
    width: 100%;
  }
  p {
    color: $col-white;
  }
  button {
    width: 100%;
  }
  .passwordRecovery {
    margin-top: 0.25rem;
    display: flex;
    flex-flow: column;
    gap: 0.5rem;
    .underline {
      color: $col-primary-90;
    }
  }
}
