@use 'styles/abstracts' as *;

.importTariffModal {
  width: 25rem;
  height: 33.75rem;
  .cardContainer {
    position: absolute;
    top: 7rem;
    right: 0;
    bottom: 5rem;
    padding: 0.5rem 0.5rem;
    background-color: $col-primary-0;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 100%;
    border-top: 1px solid $col-primary-60;
    border-bottom: 1px solid $col-primary-60;
    overflow-y: auto;
  }
  .btnWrapper {
    position: absolute;
    bottom: 1.5rem;
    left: 0;
    display: flex;
    gap: 0.75rem;
    justify-content: center;
    width: 100%;
    .btn {
      width: 10.625rem;
      height: 2.5rem;
    }
  }
}

.selected {
  border: 1px solid $col-primary-60;
  background-color: $col-primary-20;
}
