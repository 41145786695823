@use 'styles/abstracts' as *;

.container {
  padding: 0 !important;
  width: 100%;

  :global(.MuiPaper-root) {
    border: none !important;
    box-shadow: none !important;
  }

  :global(.MuiButtonBase-root) {
    p {
      font-family: $titillium !important;
      font-weight: 400 !important;
      font-size: 1.25rem !important;
      color: $col-primary-90 !important;
      display: flex;
      align-self: center;
      position: absolute;
      left: 40px;
    }
  }
  :global(.MuiAccordionSummary-expandIconWrapper) {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;
    color: $col-primary-100;
    background-color: $col-primary-10;
    border-radius: 0.75rem;
  }
  :global(.MuiAccordionSummary-content.Mui-expanded) {
    margin: 0 !important;
  }
  :global(.Mui-expanded) {
    min-height: 2.5rem !important;
    border-top-right-radius: 0.75rem;
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .details {
    padding: 0 1rem 1rem 1rem !important;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    background-color: $col-primary-10;
    display: flex;
    gap: 2rem;
    > div {
      flex-grow: 1;
      width: 100%;
    }
  }
}

