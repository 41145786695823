@use 'styles/abstracts' as *;

.card {
  padding: 0.25rem 0.5rem 0.25rem 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background-color: $col-primary-0;
  @media screen and (max-width: 1200px) {
    display: none;
  }
  .imageAndText {
    margin-left: 4px;
  }
  p {
    &.userName {
      font-weight: 600;
      font-size: 14px;
      color: $col-primary-100;
    }
    &.role {
      font-weight: 400;
      font-size: 12px;
      color: $col-primary-60;
    }
  }
}

