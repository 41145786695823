@use 'styles/abstracts' as *;

.paymentFilterContainer {
  p {
    margin-bottom: 0.5rem;
  }
  label {
    color: $col-primary-100;
  }
  :global(.MuiInputBase-root) {
    margin-top: 0.25rem;
    border-radius: 0.5rem;
    border: 1px solid $col-primary-60;
    height: 2.25rem;
    display: flex;
    align-items: center;
    padding: 0 !important;
  }
  .paymentFilter {
    display: flex;
    align-items: flex-start;
    gap: 8rem;
    margin-top: 0.5rem;
  }
  .paymentFilterFirstRow {
    display: flex;
    gap: 2rem;
  }
  .paymentFilterSecondRow {
    padding-top: 1.5rem;
  }
}

