@use 'styles/abstracts' as *;

.fieldOrganizerTypography {
  padding: 0px !important;
  margin: 0px !important;
  width: 50% !important;
  align-items: flex-end !important;
  :global(.MuiAccordionDetails-root) {
    padding: 0 !important;
  }
  flex-direction: column;
  align-items: flex-start !important;
  gap: 1.5rem;
}

.slotsWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  .timeSlotsContainer {
    display: grid;
    grid-template-columns: 1fr;
    p {
      margin-left: 4rem;
    }
  }
}

