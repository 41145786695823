@use 'styles/abstracts' as *;

.container {
  font-size: small;
  color: $col-white;
  display: grid;
  height: 1.75rem;
  width: 5rem;
  padding-right: 0.75rem;
  border: solid 1px $col-primary-60;
  border-radius: 0.5rem;
  background-color: $col-primary-10;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1.5rem auto;
  grid-template-rows: 1.75rem;
  gap: 0.125rem;
  user-select: none;
  cursor: pointer;
}

.open {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  svg {
    transform: rotate(180deg);
  }
}

.dropdown {
  display: grid;
  justify-content: center;
  padding: 0.25rem;
  align-items: center;
  border: solid 1px $col-primary-60;
  user-select: none;
  gap: 0.25rem;
  font-family: $titillium;
  font-size: small;
  color: $col-white;
  z-index: 2;
  width: 5rem;
  background-color: $col-primary-10;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  & > label {
    cursor: pointer;
  }
}
