@use 'styles/abstracts' as *;

.field {
  word-wrap: break-word;
}
.refunded {
  border: 1px solid $col-secondary-80;
}

.canceled {
  border: 1px solid $col-error-90;
}

.created {
  border: 1px solid $col-primary-100;
}

.capitalize {
  text-transform: capitalize;
}
.paid {
  position: relative;
}
.refundButton {
  position: absolute;
  right: 1rem;
  top: 1.25rem;
}

.modal {
  width: 25rem;
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'text text'
    'buttons buttons';
  gap: 1.5rem;
  span {
    grid-area: text;
  }
  div {
    grid-area: buttons;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.875rem;
    button {
      width: 10.625rem;
      height: 2.5rem;
      border-radius: 0.5rem;
      box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.12);
    }
    .cancelButton {
      background-color: transparent;
      color: $col-primary-90;
    }
    .confirmButton {
      background-color: $col-secondary-60;
      color: $col-primary-0;
    }
  }
}

.accordionContainer {
  padding: 0 0.5rem;
  :global(.MuiButtonBase-root) {
    padding-left: 0.5rem !important;
  }
  :global(.MuiAccordionDetails-root) {
    padding: 0 16px 0 !important;
  }
}

.infoRefund {
  color: $col-white;
  text-align: right;
  margin-right: 0.5rem;
}

