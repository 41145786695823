@use '../abstracts' as *;

.MuiTypography-root {
  font-family: $titillium !important;
}

.MuiRadio-root {
  width: 2.375rem;
  height: 2.375rem;
}
