@use 'styles/abstracts' as *;

.loadedFile {
  width: 100%;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  .fileWrap {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    background-color: $col-primary-20;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    width: 100%;
    .fileIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.75rem;
      width: 2.75rem;
      padding: 0.75rem;
      border-radius: 0.5rem;
      background-color: $col-primary-40;
    }
    .fileSize {
      opacity: 0.5;
    }
  }
  .deleteFile {
    background-color: $col-primary-10;
  }
}
