@use 'styles/abstracts' as *;

.container {
  height: calc(100vh - 250px);
  overflow: auto;
  .header {
    display: flex;
    gap: 0.875rem;
    margin: 1rem;
    justify-content: space-between;
    .backButton {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.dashboard {
  padding: 0 1.5rem;
  background-color: $col-primary-10;
  padding-bottom: 1.5rem;
  border-bottom: 0.0625rem solid $col-primary-60;
}
