@use 'styles/abstracts' as *;

.container {
  display: flex;
  width: calc(100% - 3.8rem);
  height: 100dvh;
  background-color: $col-primary-0;
  .left {
    overflow: auto;
    position: relative;
    width: 34.375rem;
    border-right: 0.0625rem solid $col-primary-60;
  }
  .map {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: $size-3;
    color: $col-white;
    width: calc(100% - 34.375rem);
  }
}

