@use 'styles/abstracts' as *;

.toggle {
  display: flex;
  gap: $size-0;
  width: 100%;
  display: flex;
  button {
    flex-grow: 1;
    height: 3rem;
    min-width: fit-content;
    background-color: transparent;
    color: $col-primary-100;
    svg {
      fill: $col-primary-100;
    }
  }
  .active {
    background-color: $col-primary-60;
    color: $col-white;
    svg {
      fill: $col-white;
    }
  }
}
