@use 'styles/abstracts' as *;

.container {
  :global(.MuiPaper-root) {
    border: none !important;
    box-shadow: none !important;
  }

  :global(.MuiButtonBase-root) {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    padding: 0px !important;
  }

  :global(.MuiAccordionSummary-expandIconWrapper) {
    color: $col-primary-100 !important;
  }

  .title {
    font-family: $titillium !important;
    color: $col-primary-100 !important;
    font-weight: 400 !important;
    font-size: 1.25rem !important;
  }

  :global(.MuiAccordionDetails-root) {
    padding: 0 !important;
  }

  :global(.MuiAccordionSummary-content.Mui-expanded) {
    margin: 0 !important;
  }

  :global(.MuiAccordionSummary-expandIconWrapper) {
    transform: none !important;
    transition: none !important;
  }
}

:global(div.MuiAccordion-root) {
  background-color: $col-primary-0;
  color: $col-primary-60 !important;
  border: 1px solid $col-primary-40;
  border-radius: 0.5rem;
}
