@use 'styles/abstracts' as *;

.priceContainer {
  display: flex;
  gap: 0.7rem;
  justify-content: space-between;
  input {
    gap: 0;
  }
  > div {
    width: 100%;
  }
  .label {
    margin-left: 0.5rem;
  }
  .error {
    border: 1px solid #fb9aa0;
  }
}
