@use 'styles/abstracts' as *;

.tariffRuleContainer {
  background-color: $col-primary-0;
  width: 100%;
  height: 100dvh;
  overflow: auto;
  padding: 1rem 1.5rem 1rem 2.25rem;
  :global(.Mui-expanded) {
    min-height: 40px !important;
  }
  :global(.MuiSelect-nativeInput:disabled) {
    -webkit-text-fill-color: transparent !important;
  }
}

.tariffRuleTitle {
  margin-bottom: 0.8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tariffConfContainer {
  .save {
    display: flex;
    justify-content: flex-end;
    button {
      margin: 1rem 0 0 0;
    }
  }
  .divider {
    margin: 0.5rem 0 !important;
  }
}

