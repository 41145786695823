.document {
  height: 100dvh;
  width: 100%;
  display: flex;
  align-items: center;

  flex-direction: column;
}

.page {
  width: 100%;
  max-width: 600px;
  height: fit-content;
  min-height: fit-content;
  * {
    height: fit-content !important;
    width: 100% !important;
    object-fit: contain;
  }
}

