@use 'styles/abstracts' as *;

.paymentValueContainer {
  display: flex;
  flex-flow: column;
  align-items: flex-start;

  :global(.MuiSlider-root) {
    margin: 0 !important;
  }
  .labelContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5625rem;
    width: 21rem;
    .count {
      color: white;
    }
  }
  .slider {
    width: 19rem;
    color: $col-primary-60;
    margin-left: 0.625rem !important;
    :global(.MuiSlider-markLabel) {
      font-family: $titillium;
      color: $col-primary-90;
    }
    :global(.MuiSlider-thumb) {
      border-radius: 50%;
      border: 1px solid $col-primary-60;
      background-color: $col-primary-40;
    }
    :global(.MuiSlider-valueLabel) {
      color: $col-primary-100;
      font-family: $titillium;
      background-color: $col-primary-40;
    }
    :global(.MuiSlider-mark) {
      background-color: $col-primary-60;
    }
    :global(.MuiSlider-markLabel) {
      top: -1.25rem;
    }
  }
}
