@use 'styles/abstracts' as *;

.container {
  display: flex;
  align-items: center;
  gap: 0.68rem;
  width: 100%;
  margin-bottom: 1px;
  &:focus-within {
    border-bottom: 1px solid $col-primary-90;
    margin-bottom: 0;
  }
  input {
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 1rem;
    color: $col-primary-90;
    width: 100%;
    font-family: 'Titillium Web';
    &::placeholder {
      color: $col-primary-90;
      font-weight: 600;
      font-size: 1rem;
    }
  }
}
