@use 'styles/abstracts' as *;

.container {
  background-color: $col-primary-10;
  padding: 1rem 1.5rem 0.75rem;
  margin-bottom: 0.5rem;
  .topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 50%;
    }
  }
  .detailsGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 1rem;
    b {
      color: $col-primary-100;
    }
    .gridItemFour {
      justify-self: end;
      .edit {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.25rem;
        height: 2.25rem;
        background-color: $col-primary-10;
        border-radius: 0.5rem;
        &:hover {
          background-color: $col-primary-20;
          svg path {
            fill: $col-primary-80;
          }
        }
        &:active svg path {
          fill: $col-primary-100;
        }
        &:disabled svg path {
          fill: $col-primary-40;
        }
      }
    }
    .gridItemFive,
    .gridItemEight {
      grid-column-start: 1;
      grid-column-end: 1;
    }
    .data {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 300px;
    }
    .toUpperCase {
      text-transform: uppercase;
    }
  }
}

