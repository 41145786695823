@use 'styles/abstracts' as *;

.blacklistSwitch {
  width: 42px !important;
  height: 26px !important;
  padding: 0px !important;
  margin-right: 0.5rem;
  display: flex;

  :global(.MuiSwitch-switchBase) {
    padding: 0px;
    margin: 2px;
    transition-duration: 300ms;
    &:global(.Mui-checked) {
      transform: translateX(16px);
      color: $col-white;
      :global(.MuiSwitch-thumb) {
        box-sizing: border-box;
        color: $col-white;
        width: 22px;
        height: 22px;
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0px;
          top: 0px;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url('../../../../public/assets/svg/circle_blacklist.svg');
        }
      }
      + :global(.MuiSwitch-track) {
        background-color: $col-error-60 !important;
        opacity: 1;
        border: 0;
      }
      :global(&.Mui-disabled) + :global(.MuiSwitch-track) {
        opacity: 0.5;
      }
    }
  }
  :global(.MuiSwitch-thumb) {
    box-sizing: border-box;
    color: $col-primary-60;
    width: 22px;
    height: 22px;
  }
  :global(.MuiSwitch-track) {
    border-radius: 13px;
    background-color: $col-primary-10;
    opacity: 1;
  }
}

:global(.MuiFormControlLabel-label.Mui-disabled) {
  color: $col-primary-40 !important;
}
