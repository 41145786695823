@use 'styles/abstracts' as *;

.container {
  background-color: $col-primary-10;
  padding: 15px;
  border-radius: $size-1;
  display: flex;
  align-items: center;
  gap: 0.3125rem;
  b {
    text-align: center;
    color: $col-white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .chips {
    display: flex;
    gap: $size-0;
    margin-left: auto;
  }
  .checkIcon {
    display: flex;
    padding: 0.625rem;
  }
  &:hover {
    background-color: $col-primary-20;
  }
}
.border {
  border: 1px solid $col-primary-60;
}

