@use 'styles/abstracts' as *;

.label {
  color: $col-primary-100;
}

.inputs {
  display: flex;
  gap: 0.5rem;
  .input {
    width: 6.25rem;
  }

  :global(.MuiTextField-root) {
    &:global(.Mui-disabled) {
      -webkit-text-fill-color: #043f86;
    }
  }

  :global(.MuiInputBase-root.Mui-focused) {
    border-color: $col-primary-90;
  }

  :global(.MuiInputBase-input) {
    color: white;
  }

  :global(.MuiInputBase-input::placeholder) {
    color: #1072e7 !important;
    opacity: 1;
    font-family: $titillium;
    font-size: 1rem;
    font-weight: 400;
  }

  :global(.MuiAutocomplete-option) {
    background-color: $col-primary-10;
    color: $col-white;
    font-family: $titillium;
  }

  :global(.MuiAutocomplete-listbox) {
    background-color: $col-primary-0;
    &:hover {
      background-color: $col-primary-10;
    }
  }
  :global(.MuiOutlinedInput-notchedOutline) {
    border: none;
  }

  :global(.MuiOutlinedInput-root.Mui-error) {
    border: 1px solid #fb9aa0;
  }

  :global(.MuiFormHelperText-root) {
    color: #fb9aa0;
  }

  :global(.MuiOutlinedInput-root) {
    border: 1px solid #1072e7;
    border-radius: 8px;
    &:hover {
      background-color: $col-primary-10;
    }
  }

  :global(.MuiInputLabel-root) {
    position: relative;
    top: 12px;
  }

  :global(.MuiInputLabel-root.Mui-error) {
    color: #fb9aa0;
  }

  :global(.MuiInputLabel-root) {
    color: #c3dfff;
  }
}

