@use 'styles/abstracts' as *;

.dataGrid {
  height: 100%;
  overflow: auto;
  &::-webkit-scrollbar,
  ::-webkit-scrollbar {
    width: 0;
  }
  :global(.MuiDataGrid-root) {
    font-family: $titillium;
    font-size: small;
    color: $col-white;
    border: none !important;
    button[aria-label='Export'] {
      display: none;
    }
  }
  :global(.MuiDataGrid-main) {
    margin: 0 1.5rem;
  }
  :global(.MuiInputBase-root) {
    width: 90%;
    font-family: $titillium;
    ::placeholder {
      color: $col-primary-100 !important;
    }
  }
  :global(
      .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before
    ) {
    border-bottom: 2px solid transparent;
  }
  :global(.MuiInput-root:after) {
    border-bottom: 2px solid transparent;
  }
  :global(.MuiDataGrid-columnHeaders) {
    border: none;
    &:focus,
    &:focus-within {
      outline: none !important;
    }
  }
  :global(.MuiDataGrid-columnHeader)[data-field='export-dropdown'] {
    padding-right: 0px;
    :global(.MuiDataGrid-columnHeaderTitleContainer) {
      justify-content: unset;
    }
  }
  :global(.MuiDataGrid-root .MuiDataGrid-columnHeader:focus) {
    outline: none;
  }
  :global(.MuiDataGrid-columnHeaderTitleContainer) {
    display: flex;
    flex-flow: row-reverse;
    justify-content: flex-end;
  }
  :global(.MuiDataGrid-sortIcon) {
    opacity: 1;
    color: $col-white;
  }
  :global(.MuiDataGrid-row) {
    border-radius: 0.5rem;
    background-color: $col-primary-10;
    height: 52px !important;
    min-height: 52px !important;
    &:hover {
      background-color: $col-primary-20 !important;
    }
  }
  :global(.MuiDataGrid-toolbarContainer) {
    padding: 0;
    background-color: $col-primary-10;
  }
  :global(.MuiFormControl-root) {
    font-family: $titillium;
    padding: 0rem 1.5rem 0.3rem 1.5rem;
    width: 100%;
    border-bottom: 0.0625rem solid $col-primary-60;
    svg {
      fill: $col-primary-90;
    }
    input {
      color: $col-primary-90;
    }
    button[aria-label='Clear'] {
      color: $col-primary-90;
    }
  }
  :global(.MuiDataGrid-virtualScrollerContent) {
    max-height: 100% !important;
  }
  :global(.MuiDataGrid-virtualScrollerRenderZone) {
    gap: 0.25rem;
  }
  :global(.MuiDataGrid-cell) {
    border: none !important;
    padding: 0 !important;
    &:focus,
    &:focus-within {
      outline: none !important;
    }
  }
  :global(.MuiDataGrid-cell[data-colindex='0']) {
    padding: 10px !important;
  }
  :global(.MuiDataGrid-iconSeparator) {
    color: transparent !important;
  }
  :global(.MuiToolbar-root),
  :global(.MuiDataGrid-footerContainer) {
    color: white !important;
    border: none;
    :global(.MuiInputBase-root) {
      width: initial;
    }
  }
  :global(.MuiTablePagination-root) {
    color: white !important;
  }
  :global(.MuiTablePagination-selectIcon) {
    color: $col-primary-60 !important;
  }

  :global(.MuiTablePagination-select) {
    border: 1px solid $col-primary-60 !important;
    border-radius: 0.5rem !important;
  }
  :global(.MuiIconButton-root[disabled]) {
    :global(.MuiSvgIcon-root) {
      color: $col-primary-20 !important;
    }
  }
  :global(.MuiDataGrid-footerContainer) {
    margin-bottom: 100px;
    margin-right: 1.5rem;
  }
  :global(.MuiTablePagination-selectLabel) {
    font-family: $titillium;
  }
}

.customFooter {
  justify-content: right !important;
  align-content: center;
  :global(.MuiTablePagination-displayedRows) {
    font-family: $titillium !important;
  }
  input {
    margin-right: $size-0;
  }
}

:global(.MuiDataGrid-toolbarContainer) {
  :global(.MuiSvgIcon-root)[data-testid='SearchIcon'] {
    margin-right: 0.5rem;
    path {
      d: path(
        'M16.5623 15.6376C18.1684 13.7918 18.9952 11.3946 18.8683 8.9511C18.7415 6.50763 17.6709 4.20885 15.8823 2.53929C14.0937 0.869736 11.7267 -0.0401688 9.28029 0.00136161C6.83388 0.0428921 4.49914 1.03261 2.7682 2.76192C1.03727 4.49122 0.045352 6.82502 0.00151693 9.27138C-0.0423182 11.7177 0.865355 14.0856 2.53323 15.8758C4.2011 17.666 6.49887 18.7387 8.94222 18.8679C11.3856 18.997 13.7836 18.1725 15.6309 16.5681L21.0681 22L21.9986 21.0695L16.5623 15.6376ZM17.5567 9.53922C17.5544 11.6665 16.7084 13.706 15.2042 15.2103C13.7001 16.7146 11.6607 17.5609 9.53339 17.5634C7.94671 17.5627 6.39585 17.0916 5.0769 16.2096C3.75795 15.3276 2.73013 14.0744 2.12338 12.6083C1.51662 11.1422 1.35818 9.52912 1.66809 7.973C1.978 6.41688 2.74232 4.98758 3.86446 3.86581C4.9866 2.74404 6.41614 1.98017 7.97236 1.67077C9.52859 1.36137 11.1416 1.52034 12.6075 2.12758C14.0734 2.73482 15.3263 3.76305 16.2079 5.08229C17.0894 6.40153 17.5601 7.95254 17.5602 9.53922H17.5567Z'
      );
    }
  }
}

