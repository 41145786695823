@use 'styles/abstracts' as *;

.container {
  margin-top: 0 !important;
  background-color: $col-primary-10;
  .generalContainer {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 0.5rem;
    > div {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
