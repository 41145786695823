@use 'styles/abstracts' as *;

.popup {
  z-index: 1;
  max-width: 22rem;
  background-color: $col-primary-10;
  padding: $size-1;
  border-radius: 0.75rem;
  display: grid;
  grid-template-columns: 1fr 1.75rem;
  grid-template-rows: 1fr;
  gap: 0.5rem;
  border: solid 1px $col-primary-60;
  ::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    border-width: 7px;
    border-style: solid;
    border-color: transparent $col-primary-60 transparent transparent;
  }
  ::after {
    content: '';
    position: absolute;
    top: 50%;
    right: calc(100% - 2px);
    transform: translateY(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: transparent $col-primary-10 transparent transparent;
  }
  p {
    display: flex;
    align-items: center;
  }
  button {
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 0.5rem;
    background-color: $col-secondary-60;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .totalAmount {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
}
div.refPopup {
  :global(div.mapboxgl-popup-tip) {
    display: none !important;
  }
  :global(div.mapboxgl-popup-content) {
    display: none !important;
  }
}

