@use 'styles/abstracts' as *;

.dashboardData {
  width: 100%;
  height: 6.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 3.5rem;
  border-radius: 0.5rem;
  border: 1px solid $col-primary-60;
  .data {
    display: flex;
    flex-flow: column;
    align-items: center;
    h1 {
      color: $col-primary-100;
    }
    span {
      color: $col-white;
      height: 1.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

