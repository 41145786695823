@use 'styles/abstracts' as *;

.searchbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  padding: 0 $size-3;
  gap: $size-1;
  border-bottom: 0.0625rem solid $col-primary-60;
  p {
    color: $col-primary-100;
  }
}
