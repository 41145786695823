@use 'styles/abstracts' as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  width: 100%;
  .buttons {
    display: flex;
    button {
      flex-grow: 1;
      height: 3rem;
      min-width: fit-content;
      background-color: transparent;
      color: $col-primary-100;
      svg {
        fill: $col-primary-100;
      }
    }
    .active {
      background-color: $col-primary-60;
      color: $col-white;
      svg {
        fill: $col-white;
      }
    }
  }
}
