@use 'styles/abstracts' as *;

.header {
  display: flex;
  flex-direction: column;
  gap: $size-1;
  justify-content: center;
  padding: 0 $size-3;
  height: 10rem;
  border-bottom: 0.0625rem solid $col-primary-60;
  &.headerWithFilter {
    height: 7.5rem;
    transform: translateY(-0.8125rem);
    border-bottom: none;
  }
  .searchbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem 0;
    gap: $size-1;
    p {
      color: $col-primary-100;
    }
    input[type='search']::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
    .filterButton {
      position: absolute;
      z-index: 1;
      right: 1.5rem;
      top: 7.1875rem;
      .active {
        position: absolute;
        top: -0.25rem;
        right: 0.4rem;
      }
    }
  }
}

.cardList {
  height: calc(100% - 10rem);
  display: flex;
  flex-direction: column;
  gap: $size-0;
  padding-right: $size-1;
  padding-top: $size-1;
  margin: 0 $size-2 0 $size-2;
  overflow: auto;
}

