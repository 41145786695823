@use 'styles/abstracts' as *;

.buttonsWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.625rem;
}

.confirmModal {
  width: 25rem;
  .buttonsModalWrap {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.625rem;
  }
}

