@use 'styles/abstracts' as *;

.infoContainer {
  height: auto;
  left: 84px;
  width: 100%;
  top: 302px;
  padding: 0 1.5rem;
  .accordionTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .isOnGoing {
      display: flex;
      gap: 0.25rem;
      align-items: center;
      background-color: $col-primary-10;
      border-radius: 0.5rem;
      width: fit-content;
      padding: 0.25rem 0.5rem 0.25rem 0.125rem;
      .icon {
        width: 1rem;
        height: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .label {
        font-size: 0.75rem;
      }
    }
  }
  :global(.MuiTypography-root) {
    width: 100% !important;
  }
  :global(.MuiButtonBase-root) {
    padding-left: 0.5rem !important;
  }
}

.field {
  word-wrap: break-word;
}

