@use 'styles/abstracts' as *;

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.0625rem solid $col-primary-60;
  .title {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  .back {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: $size-1 $size-3;
    cursor: pointer;
  }
  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.5rem;
    .btn {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}
.grid {
  display: grid;
  grid-template-columns: 1fr;
  padding: 1rem 0 1.5rem;
  height: calc(100dvh - 3.6rem);
  grid-template-rows: auto 3rem;
  .sortingButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .createBtn {
    margin: 0.5rem 1.5rem 0;
    width: fit-content;
    justify-self: end;
    align-self: end;
  }
  .noresults {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
}

