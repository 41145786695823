@use 'styles/abstracts' as *;

.emptyContainer {
  height: 100%;
  width: calc(100% - 34.375rem);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: $col-primary-0;
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  background-color: $col-primary-0;
  .card {
    padding: 0 1.5rem;
  }
  .message {
    display: flex;
    flex-flow: column;
    gap: 0.5rem;
    padding: 1rem;
    margin: 0.75rem 1.5rem;
    background-color: $col-primary-20;
    border-radius: 1rem;
    .date {
      color: $col-primary-60;
    }
    .detail {
      color: $col-white;
    }
  }
}
