@use 'styles/abstracts' as *;

.areaList {
  height: calc(100% - 100px);
  padding-top: $size-1;
  .cardList {
    height: calc(100% - $size-10);
    display: flex;
    flex-direction: column;
    gap: $size-0;
    padding-right: $size-1;
    margin: 0 $size-1 0 $size-2;
    overflow: auto;
  }
  .createBtn {
    position: absolute;
    right: $size-3;
    bottom: $size-3;
  }
  .lastItem {
    div {
      background-color: transparent;
    }
  }
}
