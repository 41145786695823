$col-primary-0: #010d1d;
$col-primary-10: #02142a;
$col-primary-20: #011c3d;
$col-primary-40: #043f86;
$col-primary-50: #0557ba;
$col-primary-60: #1072e7;
$col-primary-80: #509ffd;
$col-primary-90: #8dbef9;
$col-primary-100: #c3dfff;
$col-grey-0: #1d1e20;
$col-grey-10: #333538;
$col-grey-20: #515559;
$col-grey-40: #6f747a;
$col-grey-50: #9ca2a9;
$col-grey-60: #c4cad0;
$col-grey-80: #d9dee3;
$col-grey-90: #eef2f8;
$col-grey-100: #f4f8fd;
$col-secondary-0: #393a00;
$col-secondary-10: #6a6c00;
$col-secondary-20: #9c9f00;
$col-secondary-40: #d1d600;
$col-secondary-50: #f8fd00;
$col-secondary-60: #fbff36;
$col-secondary-80: #fcff7e;
$col-secondary-90: #fdffb1;
$col-secondary-100: #feffd7;
$col-error-40: #85050d;
$col-error-60: #f22d39;
$col-error-90: #fb9aa0;
$col-confirm-40: #008a2f;
$col-confirm-60: #0ee758;
$col-confirm-90: #86f0aa;
$col-white: #fff;
$col-black: #000;
