@use 'styles/abstracts' as *;

.container {
  width: 100%;
  height: 100dvh;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('../../../public/login.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  gap: 1rem;
}

.disclaimer {
  width: 22rem;
  max-width: 100%;
  padding-top: 1.5rem;
}

.card {
  width: 22rem;
  max-width: 100%;
  min-height: fit-content;
  padding: 1rem;
  background-color: $col-primary-10;
  border-radius: 0.75rem;
  border: 0.0625rem solid $col-primary-60;
  display: flex;
  flex-flow: row;
  gap: 1.3125rem;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  text-align: center;
  .icon {
    background-color: $col-primary-50;
    width: fit-content;
    aspect-ratio: 1;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
  }
}

