@use 'styles/abstracts' as *;

.container {
  width: 100%;
  height: 4.625rem;
  border-radius: 0.5rem;
  background-color: $col-primary-10;
  padding: 0 1rem;
  margin-bottom: 0.25rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      b {
        color: $col-white;
      }
      .yellow {
        color: $col-secondary-60;
      }
    }
    .status {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      .date {
        color: $col-primary-100;
      }
    }
  }
  .description {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $col-primary-100;
    margin-top: 0.25rem;
  }
}

.selected {
  background-color: $col-primary-20;
  border: 1px solid $col-primary-60;
}
