@use 'styles/abstracts' as *;

.backdrop {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(1, 13, 29, 0.6);
  z-index: 2;
  .modal {
    position: relative;
    padding: 1.5rem;
    border-radius: $size-1;
    border: 1px solid $col-primary-60;
    background-color: $col-primary-10;
    ::-webkit-scrollbar {
      display: none;
    }
    ::-webkit-scrollbar-thumb {
      display: none;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.3rem;
      svg {
        width: 0.7rem;
      }
      b {
        color: $col-white;
      }
      .close {
        position: absolute;
        top: $size-2;
        right: $size-2;
        background-color: transparent;
        svg {
          fill: $col-white;
        }
      }
    }
    .scrollable {
      display: flex;
      flex-flow: column;
      overflow: auto;
    }
  }
}

