@use 'styles/abstracts' as *;

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    display: flex;
    align-items: center;
  }
}
.specific {
  margin-bottom: 0.8rem;
}
.radioFilters {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(2, auto);
  gap: 1.5rem;
  grid-column-gap: 6rem;
  p {
    padding: 0;
  }
}
