@use 'styles/abstracts' as *;

.container {
  display: flex;
  height: 100dvh;
  background-color: $col-primary-0;
  width: calc(100% - 3.8rem);
  overflow: hidden;
  .left {
    flex-direction: column;
    border-right: 0.0625rem solid $col-primary-60;
    width: 34.375rem;
    .topTools {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: $size-1;
      padding: 1.25rem 1.5rem 1rem;
      border-bottom: 0.0625rem solid $col-primary-60;
      .bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .filterButton {
          position: relative;
          .active {
            position: absolute;
            top: -0.25rem;
            right: 0.4rem;
          }
        }
      }
      p {
        color: $col-primary-100;
      }
    }
    .cardList {
      display: flex;
      flex-direction: column;
      gap: $size-0;
      padding: $size-2 $size-3;
      overflow-y: auto;
      height: 100%;
    }
  }
  .right {
    width: calc(100% - 34.375rem);
    display: flex;
    flex-direction: column;
    overflow: auto;
    .background {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

