@use 'styles/abstracts' as *;

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
  label {
    color: $col-primary-100;
    margin-bottom: 0.25rem;
    font-size: 0.75rem;
    font-weight: 600;
  }
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .textInput {
    border: 1px solid $col-primary-60;
    color: $col-white;
    font-family: $titillium;
    outline: none;
    height: 2.25rem;
    background-color: $col-primary-0;
    border-radius: 0.5rem;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    width: 100%;
    &::placeholder {
      color: $col-primary-60 !important;
    }
    &:hover {
      background-color: $col-primary-10;
    }
    &:focus {
      border-color: $col-primary-90;
    }
  }
  .blockWithCheckbox {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    .checkbox {
      width: 50%;
      display: flex;
      align-items: center;
    }
    .fieldOfCheckbox {
      width: 50%;
    }
  }
  .error {
    border: 1px solid $col-error-90 !important;
  }
  input:disabled {
    background-color: $col-primary-10;
  }
  .checkboxLabel {
    color: white;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0;
  }
  .buttons {
    display: flex;
    margin-top: 1.25rem;
    gap: $size-2;
    justify-content: flex-end;
    width: 100%;
    justify-content: center;
    button {
      width: 35%;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $col-primary-10 inset !important;
    -webkit-text-fill-color: $col-primary-100 !important;
  }
}

