@use 'styles/abstracts' as *;

.pickerContainer {
  display: flex;
  flex-direction: column;
  label {
    margin-left: 0.5rem;
    color: $col-primary-100;
  }
  :global(.react-datepicker-wrapper) {
    width: fit-content;
    .datePicker,
    .react-datepicker__input-container,
    input {
      border-radius: 0.5rem;
      border: 1px solid $col-primary-60;
      height: 2.25rem;
      width: 9.6875rem;
      color: $col-white;
      font-size: 1rem;
      font-weight: 400;
      padding: 1rem;
      background-color: transparent;
      &:focus {
        border-color: $col-primary-90;
      }
      &:hover {
        background-color: $col-primary-10;
      }
    }
  }
  ::placeholder {
    color: $col-primary-60 !important;
    font-family: $titillium;
  }
  & textarea:focus,
  input:focus {
    outline: none;
  }
  svg {
    position: absolute;
    right: 0.625rem;
    top: 0.875rem;
  }
}

