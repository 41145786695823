@use 'styles/abstracts' as *;

.snackbarStyle {
  :global(.MuiPaper-root) {
    background-color: $col-primary-20;
    min-width: 288px;
    color: $col-white !important;
    font-family: $titillium;
  }
  :global(.MuiAlert-action) {
    padding: 0 !important;
  }
  :global(.MuiAlert-message) {
    overflow: hidden !important;
    padding: 10px 0 !important;
  }
  :global(.MuiAlert-icon) {
    padding: 10px 0 !important;
  }
}
:global(.MuiSnackbar-root) {
  right: 0 !important;
  left: 50% !important;
  bottom: 30px !important;
  min-width: 288px !important;
  transform: translate(-50%);
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

