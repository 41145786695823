@use 'styles/abstracts' as *;

.filtersContainer {
  height: calc(100%);
  overflow: hidden;
  .dashboard {
    padding: 0 1.5rem 1rem 1.5rem;
    background-color: $col-primary-10;
  }
  .header {
    padding: 1.4rem 1.5rem 0.5rem;
    border-top: 1px solid $col-primary-60;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      display: flex;
      align-items: center;
    }
  }
  .filters {
    padding: 0 1.5rem 1rem;
    height: calc(100% - 200px);
    overflow: auto;
  }
}
.inputStyle {
  :global(.MuiSelect-nativeInput) {
    display: none;
  }
  :global(
      .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input
    ) {
    font-family: $titillium;
  }
}
