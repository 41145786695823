@use 'styles/abstracts' as *;

.container {
  display: flex;
  align-items: center;
  gap: 1rem;
  .error {
    border: 1px solid #fb9aa0 !important;
  }
}

