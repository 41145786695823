@use 'styles/abstracts' as *;

.container {
  display: flex;
  background-color: $col-primary-10;
  padding: $size-0 $size-1;
  border-radius: $size-1;
  gap: $size-0;
  align-items: center;
  justify-content: center;
  color: $col-primary-90;
  gap: $size-0;
  width: fit-content;
  .icon {
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .label {
    color: $col-primary-100;
    padding: 0;
  }
  .close {
    height: 1.5rem;
    width: 1.5rem;
    padding: 0.375rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

