@use 'styles/abstracts' as *;

.chip {
  display: flex;
  background-color: $col-primary-20;
  padding: $size-00 $size-0;
  border-radius: $size-0;
  gap: $size-0;
  align-items: center;
  justify-content: center;
  color: $col-primary-90;
  gap: $size-0;
  width: fit-content;
  &.active {
    color: $col-white;
    background-color: $col-primary-50;
  }
  :nth-child(2) {
    white-space: nowrap;
  }
  svg {
    width: 1rem;
    height: 1rem;
  }
}

