@use 'styles/abstracts' as *;

.container {
  width: 21%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.5rem;
  background-color: $col-primary-0;
  border-right: 1px solid $col-primary-60;
  .menuButton {
    height: 3rem;
    min-width: fit-content;
    background-color: transparent;
    color: $col-primary-100;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    svg {
      fill: $col-primary-100;
    }
  }
  .active {
    background-color: $col-primary-60;
    color: white;
    svg {
      fill: white;
    }
  }
}

