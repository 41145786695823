@use 'styles/abstracts' as *;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid $col-primary-60;
  .backButton {
    display: flex;
    align-items: center;
    span {
      color: $col-primary-60;
    }
  }
  .buttons {
    display: flex;
  }
}

.container {
  :global(.MuiAccordionSummary-expandIconWrapper) {
    margin-left: 1rem;
  }
  .fields {
    margin: 0rem 1rem;

    p {
      font-size: 1rem;
    }
    .column {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 50%;
    }
  }
  :global(.MuiTypography-root) {
    width: 100%;
  }
  .area {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .disabled {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.4rem;
      width: 7rem;
      background-color: $col-primary-10;
      border-radius: 0.25rem;
      margin: 0 1rem;
      label {
        padding-bottom: 0;
      }
    }
  }
}

.tariff {
  margin: 0 1rem;
}

