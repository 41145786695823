@use 'styles/abstracts' as *;

.container {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 320px;
  border-radius: 8px;
  background-color: $col-primary-0;
  &:focus-within {
    border-bottom: 1px solid $col-primary-0;
    margin-bottom: 0;
  }
  :global(.MuiOutlinedInput-notchedOutline) {
    border: none;
  }
  :global(.MuiInputBase-root) {
    path {
      fill: $col-primary-60;
    }
  }

  .textField {
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 1rem;
    color: $col-primary-60;
    font-family: $titillium;
    input {
      color: $col-white;
      height: 0.5rem;
      font-size: 0.75rem;
      word-wrap: break-word;
      text-wrap: nowrap;
      text-overflow: ellipsis;
      font-family: $titillium;
    }
    input:focus {
      outline: none !important;
    }
    input::placeholder {
      color: $col-primary-60 !important;
      opacity: 1;
      font-weight: 400;
      font-size: 14px;
    }
    & > div {
      border-radius: 0.5rem !important;
      &:global(.Mui-focused) {
        border: 1px solid $col-primary-90;
        padding: 0.5rem;
      }
      border: none;
      padding-right: 0.25rem !important;
      gap: 0.5rem;
    }
  }
}

.listOption {
  display: grid;
  height: 3rem;
  grid-template-columns: 1.75rem auto;
  gap: 0.5rem;
  padding: 0px;
  background-color: $col-primary-0;
  :nth-child(1) {
    display: flex;
    align-items: center;
  }
  .optionInfo {
    display: grid;
    grid-template-rows: 0.875rem 0.875rem;
    p {
      word-wrap: break-word;
      text-wrap: nowrap;
      text-overflow: ellipsis;
      text-align: left;
    }
    :nth-child(1) {
      color: $col-white;
    }
    :nth-child(2) {
      color: $col-primary-60;
    }
  }
}

.paper {
  margin-top: 0.25rem;
  background-color: $col-primary-0;
  border-radius: 0.5rem;
  ul {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: $col-primary-0;
  }
  :global(.Mui-focused) {
    border-radius: 0.5rem;
    background-color: $col-primary-10 !important;
  }
}

