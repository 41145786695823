@use 'styles/abstracts' as *;

.container {
  width: 100%;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../../public/login.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .form {
    width: 21rem;
    padding: 1.5rem;
    background-color: $col-primary-10;
    border-radius: 0.75rem;
    border: 0.0625rem solid $col-primary-60;
    display: flex;
    flex-flow: column;
    gap: 2rem;
    p {
      color: $col-white;
    }
    .btns {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1rem;
    }
  }
}

