@use 'styles/abstracts' as *;

.fieldsContainer {
  border-radius: 12px;
  background-color: $col-primary-0;
  height: fit-content;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 0.5rem;
  > :first-child {
    margin-top: 0.5rem;
  }
}
