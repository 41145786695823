@use 'styles/abstracts' as *;

.filtersContainer {
  height: calc(100% - 7.5rem);
  overflow: hidden;
  .dashboard {
    padding: 0 1.5rem 1rem;
    background-color: $col-primary-10;
  }
  .header {
    padding: 1.5rem 1.5rem 1rem;
    border-top: 1px solid $col-primary-60;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  .filters {
    height: 100%;
    padding: 0 1.5rem 1rem;
    height: calc(100% - 200px);
    overflow: auto;
    p {
      color: $col-primary-100;
    }
    .dateFilter {
      display: flex;
      align-items: flex-start;
      gap: 1rem 8rem;
      flex-wrap: wrap;
      margin-top: 0.5rem;
    }
  }
}

