@use 'styles/abstracts' as *;

.fieldOrganizerAccordion {
  padding: 0px !important;
  margin: 0px !important;
  :global(.MuiAccordionDetails-root) {
    padding: 0 !important;
  }
}
.fieldOrganizerTypography {
  padding: 0px !important;
  margin: 0px !important;
  width: 100% !important;
  flex-direction: column;
  padding-bottom: 0.75rem !important;
  label {
    color: $col-primary-90;
  }
  :global(.MuiAccordionDetails-root) {
    padding: 0 !important;
  }
  .freeTimePicker {
    width: 118px;
  }
  .timeContainer {
    display: flex;
    align-content: flex-end;
    justify-content: space-evenly;
    flex-direction: column;
    margin: 0 !important;
  }
  .costContainer {
    display: flex;
    gap: 2rem;
  }
  .freeTimePickerInput {
    display: flex;
    gap: 1rem;
  }
  .title {
    display: flex;
  }

  b {
    color: $col-primary-100;
  }
  .euroInput {
    width: 24.5rem !important;
  }
}

