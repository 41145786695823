@use 'styles/abstracts' as *;

.createBtn {
  position: absolute;
  right: $size-3;
  bottom: $size-3;
}

.importBtn {
  position: absolute;
  right: 12.75rem;
  bottom: $size-3;
}
