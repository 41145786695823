@use 'styles/abstracts' as *;

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  .title {
    color: $col-primary-100;
  }
  .inputs {
    display: flex;
    gap: 2rem;
    .wrapper {
      display: flex;
      flex-flow: column;
      width: 100%;
      .subtitle {
        margin-left: 0.5rem;
        color: $col-primary-100;
        width: fit-content;
      }
      .error {
        border: 1px solid #fb9aa0;
      }
    }
  }
}
