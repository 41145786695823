@use 'styles/abstracts' as *;

.otpWrap {
  display: flex;
  flex-direction: column;
  .inputWrap {
    display: flex;
    gap: 0.75rem;
    width: fit-content;
    margin: 0 auto;
    .otpInput {
      -moz-appearance: textfield;
      width: 2.25rem;
      height: 3rem;
      font-size: 1.25rem;
      text-align: center;
      border-radius: 0.5rem;
      border: 1px solid $col-primary-60;
      color: white;
      background-color: transparent;
      font-weight: 600;
      &:focus {
        outline: none;
      }
    }
    .otpInput::-webkit-inner-spin-button,
    .otpInput::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .otpError {
      border-color: $col-error-90;
    }
  }
  .errorLabel {
    margin-top: 2rem;
    border: 1px solid $col-primary-60;
    background-color: $col-primary-20;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.25rem;
      height: 2.25rem;
    }
  }
}

