@use 'styles/abstracts' as *;

.leftSide,
.rightSide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.leftSide {
  .label {
    margin-left: 0.5rem;
  }
  :global(.MuiFormControl-root) {
    width: 100%;
    border-radius: 0.5rem;
    font-family: $titillium;
  }
  :global(.MuiInputBase-root) {
    height: 2.25rem;
    border-radius: 0.5rem;
    color: $col-white;
    font-family: $titillium;
    svg {
      fill: $col-primary-60 !important;
    }
  }
  :global(.MuiOutlinedInput-notchedOutline) {
    border-style: none;
    color: $col-primary-60 !important;
  }
  :global(.MuiSelect-nativeInput) {
    opacity: 1;
    color: transparent !important;
    height: 2.25rem;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0 0.5rem;
    &::placeholder {
      font-size: 1rem;
      color: $col-white !important;
      font-family: $titillium;
      padding-left: 0.5rem;
    }
  }
  .error {
    border: 1px solid #fb9aa0;
  }
}
