@use 'styles/abstracts' as *;

.wrapper {
  margin-left: 0.75rem;
  display: flex;
  gap: 1.5rem;
  .formLabel {
    margin-left: 0.5rem;
    text-align: center;
    color: $col-white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
  }
}

