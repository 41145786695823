@use 'styles/abstracts' as *;

.container {
  p {
    margin: 0.375rem 0;
  }
  :global(.MuiFormControlLabel-root) {
    height: 21px;
    margin-bottom: 9px;
  }
}
