@use 'styles/abstracts' as *;

.input {
  width: 100% !important;
  border: 1px solid $col-primary-60;
  border-radius: 0.5rem;
  height: 2.25rem;
  align-items: center;
  padding: 0 0 0 1rem !important;
  font-family: $titillium;
  background-color: $col-primary-0;
  color: $col-grey-100;
  gap: 0.5rem;
  font-size: 1rem;
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    display: none;
  }
  &::placeholder {
    color: $col-primary-60 !important;
    font-size: 1rem;
  }
  &:focus {
    border: 1px solid $col-primary-90 !important;
    outline: none;
  }
  &:disabled {
    border: 1px solid $col-primary-40 !important;
    -webkit-text-fill-color: $col-primary-40 !important;
    outline: none;
  }
  &:hover {
    background-color: $col-primary-10;
  }
}

