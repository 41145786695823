@use 'styles/abstracts' as *;

.resetModal {
  width: 25rem;
  .buttons {
    display: flex;
    gap: 0.625rem;
    button {
      width: 50%;
      margin-top: 0.5rem;
    }
  }
}

