@use 'styles/abstracts' as *;

.remove {
  width: 25rem;
  .buttons {
    display: flex;
    margin: 1rem 0;
    button {
      width: 50%;
    }
  }
}
.add {
  width: 25rem;
  .motivation {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    textarea {
      background-color: transparent;
      border: 1px solid $col-primary-60;
      border-radius: 8px;
      width: 100%;
      height: 5rem;
      color: white;
      padding-left: 0.5rem;
      padding-top: 0.5rem;
      font-family: $titillium;
      font-size: 1rem;
      line-height: 1.3125rem;
      &::placeholder {
        color: $col-primary-60;
        height: 5rem;
        position: absolute;
        top: 0.5rem;
      }
      &:focus-visible {
        outline: none;
      }
    }
  }
  .buttons {
    display: flex;
    gap: 0.625rem;
    button {
      width: 50%;
      margin: 0.5rem 0;
    }
  }
}

