@use 'styles/abstracts' as *;

.container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding-left: 3rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    color: $col-primary-90;
    display: flex;
    align-items: center;
  }
  .actions {
    display: flex;
    .down {
      transform: rotate(-90deg);
    }
    .up {
      transform: rotate(90deg);
    }
  }
}
