@use 'styles/abstracts' as *;

.container {
  width: calc(100% - 1rem);
  .timeContainer {
    display: flex;
    flex-direction: column;
    margin: 0.75rem 0;
    label {
      margin-left: 0.5rem;
      color: $col-primary-100;
    }
    .error {
      border: 1px solid #fb9aa0 !important;
    }
  }
}

