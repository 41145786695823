@use 'styles/abstracts' as *;

.container {
  width: 25rem;
  .red {
    color: $col-error-90;
  }
  .buttons {
    margin-top: $size-2;
    display: flex;
    gap: $size-1;
    justify-content: flex-end;
  }
}

