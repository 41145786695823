@use 'styles/abstracts' as *;

.container {
  height: 100dvh;
  width: calc(100% - 3.8rem);
  display: flex;
  .filtersContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    padding: 1.19rem 1.5rem 0 2.25rem;
    background-color: $col-primary-0;
  }
}
