@use 'styles/abstracts' as *;

.field {
  display: flex;
  flex-flow: column;
  gap: 1rem;
}
.accordionContainer {
  padding: 0 0.5rem;
  :global(.MuiButtonBase-root) {
    padding-left: 0.5rem !important;
  }
  :global(.MuiAccordionDetails-root) {
    padding: 0 16px 0 !important;
  }
}
.redBorder {
  border: 2px solid $col-error-90;
}
.alert {
  align-self: center;
}

