@use 'styles/abstracts' as *;

.modalContainer {
  width: 19rem;
  height: fit-content;
  label {
    color: $col-primary-100;
  }
  :global(.MuiFormControlLabel-label) {
    color: $col-primary-100 !important;
  }
  :global(.MuiSvgIcon-root) {
    fill: $col-primary-60 !important;
  }
}

.distance {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-bottom: 1.5rem;
  margin-left: 0.7rem;
  .datePickerContainer {
    .datePicker,
    .react-datepicker__input-container,
    input {
      border-radius: 0.5rem;
      border: 1px solid $col-primary-60;
      height: 2.25rem;
      width: 9.9375rem;
      color: $col-white;
      font-size: 1rem;
      font-weight: 400;
      padding: 1rem;
      background-color: transparent;
      &:focus {
        border-color: $col-primary-90;
      }
      &:hover {
        background-color: $col-primary-10;
      }
      &:disabled {
        background-color: $col-primary-10;
        color: $col-primary-40;
        border-color: $col-primary-40 !important;
      }
    }
    display: grid;
    align-items: center;
    grid-template-columns: auto auto auto;
    svg {
      position: relative;
      right: 25px;
      pointer-events: none;
    }
  }
}

.days {
  display: flex;
  :global(.MuiInputBase-root) {
    background-color: $col-primary-10;
  }
}
.daySelect {
  width: 80px;
  height: 36px;
  background-color: $col-primary-10;
}

.monthForm {
  gap: 14px;
  margin-right: 2rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  :global(.MuiFormControl-root) {
    height: 36px;
    width: 159px;
    border-radius: 0.5rem;
    font-family: $titillium;
  }
  :global(.MuiInputBase-root) {
    height: 2.25rem;
    border-radius: 0.5rem;
    color: $col-white;
    font-family: $titillium;
    background-color: $col-primary-10;
    svg {
      fill: $col-primary-60 !important;
    }
  }
  :global(.MuiOutlinedInput-notchedOutline) {
    border-style: none;
  }
  :global(.MuiSelect-nativeInput) {
    opacity: 1;
    color: transparent !important;
    height: 2.25rem;
    background-color: transparent;
    border: none;
    padding: 0 1rem;
    &::placeholder {
      font-size: 1rem;
      color: $col-primary-60 !important;
      font-family: $titillium;
    }
  }
  :global(.MuiTypography-root) {
    color: $col-white;
  }
  :global(.Mui-disabled) {
    -webkit-text-fill-color: $col-primary-40 !important;
    border-color: $col-primary-40 !important;
    :global(.MuiSelect-nativeInput) {
      opacity: 0;
    }
  }
}

.labelDistance {
  margin-left: 0.5rem;
}

.numberAndDateForm {
  gap: 6px;
  margin-right: 2.25rem;
  display: flex;
  align-items: center;
  :global(.MuiFormControl-root) {
    height: 36px;
    width: 100px;
    border-radius: 0.5rem;
    font-family: $titillium;
  }
  :global(.MuiInputBase-root) {
    height: 2.25rem;
    border-radius: 0.5rem;
    color: $col-white;
    font-family: $titillium;
    background-color: $col-primary-10;
    svg {
      fill: $col-primary-60 !important;
    }
  }
  :global(.MuiOutlinedInput-notchedOutline) {
    border-style: none;
  }
  :global(.MuiSelect-nativeInput) {
    opacity: 1;
    color: transparent !important;
    height: 2.25rem;
    background-color: transparent;
    border: none;
    padding: 0 1rem;
    &::placeholder {
      font-size: 1rem;
      color: $col-primary-60 !important;
      font-family: $titillium;
    }
  }
  :global(.MuiTypography-root) {
    color: $col-white;
  }
  :global(.Mui-disabled) {
    -webkit-text-fill-color: $col-primary-40 !important;
    border-color: $col-primary-40 !important;
    :global(.MuiSelect-nativeInput) {
      opacity: 0;
    }
  }
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.customButton {
  width: 123px;
}

