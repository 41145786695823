@use 'styles/abstracts' as *;

.container {
  .filters {
    display: flex;
    align-items: center;
    gap: 2rem;
  }
  p {
    color: $col-primary-100;
    margin-bottom: 0.5rem;
  }
}
