@use 'styles/abstracts' as *;

.container {
  :global(.MuiPaper-root) {
    border: none;
    box-shadow: none;
  }
  :global(.MuiButtonBase-root) {
    min-height: auto;
    padding: 0;
    flex-direction: row-reverse;
    height: 40px;
  }
  :global(.MuiAccordionSummary-expandIconWrapper) {
    padding: 0.5rem;
    color: $col-primary-100;
  }
  :global(.MuiTypography-root) {
    font-family: $titillium;
    color: $col-primary-100;
    font-weight: 400;
    font-size: 1.25rem;
    p {
      color: $col-white;
    }
  }
  :global(.MuiAccordionSummary-content.Mui-expanded) {
    margin: 0;
  }
  :global(.MuiAccordionDetails-root) {
    padding: 0;
  }
}
