@use 'styles/abstracts' as *;

.radio {
  display: flex;
  flex-direction: row !important;
  gap: 1rem;
  padding-top: 1rem;
  label {
    margin: 0;
    gap: 0.25rem;
    &:hover :first-child svg :last-child {
      fill: $col-primary-80;
      transition: all 300ms ease;
    }
  }
}

