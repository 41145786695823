@use 'styles/abstracts' as *;

.modalContent {
  width: 26.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  .loading {
    width: 100%;
    height: 12.5rem;
    border-radius: 0.5rem;
    border: 1px dashed $col-primary-40;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .consent {
    display: flex;
    flex-direction: column;
    gap: 2.375rem;
    .switch {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }
  }
  label > b {
    color: $col-primary-100;
  }
}

