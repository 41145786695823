@use 'styles/abstracts' as *;

.container {
  display: flex;
  flex-direction: column;
  gap: $size-1;
  .buttons {
    margin-top: $size-2;
    display: flex;
    gap: $size-1;
    justify-content: flex-end;
  }
}
.denyContainer {
  display: flex;
  flex-direction: column;
  width: 21.875rem;
  gap: $size-1;
  .btn {
    margin-left: auto;
  }
}

