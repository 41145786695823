@use '../abstracts' as *;

input[type='time'] {
  color: $col-white;
  border: solid 1px $col-primary-60;
  width: 177px;
  height: 36px;
  background-color: $col-primary-0;
  border-radius: 0.5rem;
  padding-left: 0.625rem;
  &:focus {
    outline: none;
  }
  &::-webkit-calendar-picker-indicator {
    background-image: url('../../../public/assets/svg/arrow_down.svg');
    padding-right: 0.5rem;
    padding-top: 0.6rem;
  }
  &::before {
    margin-right: 0.5rem;
  }
}
