@use 'styles/abstracts' as *;

.searchBar {
  position: fixed;
  top: 16px;
  left: calc(50% + 76px);
  &.municipalityView {
    left: calc(34.375rem + 76px) !important;
  }
  text-align: center;
  z-index: 1;
  padding-top: 0.5rem;
}

.__userCard {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 1;
  padding-top: 0.5rem;
}

.mapContainer {
  width: auto;
}

.drawingTool {
  z-index: 1;
  position: absolute;
  left: 50%;
  top: $size-2;
  transform: translateX(-50%);
  display: flex;
  background-color: $col-primary-0;
  border-radius: 0.75rem;
  padding: $size-0;
  gap: $size-0;
  border: 1px solid $col-primary-60;
}

