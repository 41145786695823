@use '../abstracts' as *;

.MuiTextField-root {
  &.Mui-disabled {
    -webkit-text-fill-color: #043f86;
  }
}

.MuiInputBase-root.Mui-focused {
  border-color: $col-primary-90;
}

.MuiInputBase-input {
  color: white;
}

.MuiInputBase-input::placeholder {
  color: #1072e7;
  opacity: 1;
  font-family: $titillium;
  font-size: 1rem;
  font-weight: 400;
}

.MuiAutocomplete-option {
  background-color: $col-primary-10;
  color: $col-white;
  font-family: $titillium;
}

.MuiAutocomplete-listbox {
  background-color: $col-primary-0;
  &:hover {
    background-color: $col-primary-10;
  }
}
.MuiOutlinedInput-notchedOutline {
  border: none;
}

.MuiOutlinedInput-root.Mui-error {
  border: 1px solid #fb9aa0;
}

.MuiFormHelperText-root {
  color: #fb9aa0;
}

.MuiOutlinedInput-root {
  border: 1px solid #1072e7;
  border-radius: 8px;
  &:hover {
    background-color: $col-primary-10;
  }
}

.MuiInputLabel-root {
  position: relative;
  top: 12px;
}

.MuiInputLabel-root.Mui-error {
  color: #fb9aa0;
}

.MuiInputLabel-root {
  color: #c3dfff;
}

