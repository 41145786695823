@use '../abstracts/' as *;

body {
  font-family: $titillium;
  font-size: 1rem;
  font-weight: 400;
  color: $col-white;
  b {
    font-weight: 600;
  }
}

h1 {
  font-size: 2.25rem;
  font-weight: 600;
}

h2 {
  font-size: 1.75rem;
  font-weight: 600;
}

h3 {
  font-size: 1.375rem;
  font-weight: 600;
}

h4 {
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
}

p {
  font-weight: 400;
  font-size: 1.25rem;
  b {
    font-weight: 600;
  }
}

.small {
  font-size: 0.75rem;
  font-weight: 400;
  padding-bottom: 0.2rem;
  b {
    font-weight: 600;
  }
}

.lightBlue {
  color: $col-primary-100;
}

.primary90 {
  color: $col-primary-90;
}

.primary60 {
  color: $col-primary-60;
}

