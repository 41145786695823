@use 'styles/abstracts' as *;

.time {
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  input {
    gap: 0;
  }
  > div {
    width: 100%;
  }
}

.multiplyCheckboxLabel {
  margin-left: 0.5rem;
  font-size: 12px;
}

