@use 'styles/abstracts' as *;

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  margin-top: 1.5rem;
  .btn {
    width: 7.5rem;
  }
}

.label {
  margin: 1rem 0 0.75rem 0;
}

