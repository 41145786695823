@use 'styles/abstracts' as *;

.confirmationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  background-color: #f5f5f5;
  @media screen and (max-width: 768px) {
    padding: 1.25rem;
  }
  .confirmationCard {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    padding: 3rem 2.25rem;
    max-width: 640px;
    position: relative;
    width: 100%;
    @media screen and (max-width: 768px) {
      max-width: unset;
      padding: 3rem 1rem;
    }
    .grayBg {
      background-color: #d9dde3;
      width: 100%;
      height: 40%;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      .gradientTop {
        background: linear-gradient(
          to right,
          #011c3d,
          #509ffd,
          #ffdd00
        );
        height: 10px;
        width: 100%;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
      }
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 3rem 0 2rem 0;
      position: relative;
      .title {
        font-size: 1.25rem;
        font-weight: 600;
        color: #011c3c;
      }
      .logoMuvin {
        height: 40px;
      }
      .logoSis {
        height: 20px;
      }
    }
    .confirmationMessage {
      margin-bottom: 4rem;
      position: relative;
      background-color: #ffffff;
      border: 2px solid #c4c9cf;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 1.5rem;
      padding: 1.5rem 1.25rem 2rem 1.25rem;
      @media screen and (max-width: 480px) {
        &.confirmationMessageError {
          margin-bottom: 2rem;
        }
      }
      .animatedBox {
        height: 8rem;
        width: 8rem;
      }
      h2 {
        font-size: 2.25rem;
        color: #010e1e;
        font-weight: 700;
        text-align: center;
        @media screen and (max-width: 480px) {
          font-size: 1.75rem;
          font-weight: 600;
        }
      }
      .message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
      }
      p {
        font-size: 1rem;
        color: #010e1e;
        b {
          font-weight: 600;
          font-size: 1.375rem;
        }
      }
    }
    .support {
      padding: 0.75rem 0.5rem;
      background-color: #edf2f7;
      font-size: 0.75rem;
      font-weight: 600;
      color: #6f757b;
      margin-bottom: 4rem;
      @media screen and (max-width: 480px) {
        &.supportError {
          margin-bottom: 2rem;
        }
      }
      a {
        text-decoration: none;
        color: #1070e5;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .sisLogoLight {
        height: 20px;
      }
      .social {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        .socialIcon {
          width: 28px;
        }
      }
    }
  }
}

