@use 'styles/abstracts' as *;

.container {
  :global(.MuiFormControl-root) {
    width: 100%;
  }
  :global(.MuiInputBase-root) {
    height: 2.25rem;
    border-radius: 0.5rem;
    border: 1px solid $col-primary-60;
    color: $col-white;
    font-family: $titillium;
    background-color: $col-primary-0;
    &:hover {
      background-color: $col-primary-10;
    }
    svg {
      fill: $col-primary-60;
    }
  }
  :global(.MuiOutlinedInput-notchedOutline) {
    border-style: none;
  }
  :global(.MuiInputBase-root.Mui-focused) {
    border-color: $col-primary-90 !important;
  }
  :global(.MuiSelect-nativeInput) {
    opacity: 1;
    color: transparent !important;
    height: 2.25rem;
    background-color: transparent;
    border: none;
    padding: 0 1rem;
    bottom: -2px;
    &::placeholder {
      font-size: 1rem;
      color: $col-primary-60 !important;
      font-family: $titillium;
    }
  }
  :global(.MuiTypography-root) {
    color: $col-white;
  }
  :global(.Mui-disabled) {
    input:disabled {
      color: transparent !important;
      -webkit-text-fill-color: transparent !important;
    }
    input[placeholder='Non ci sono opzioni selezionabili.'] {
      color: $col-primary-40 !important;
      -webkit-text-fill-color: $col-primary-40 !important;
    }
    -webkit-text-fill-color: $col-primary-40 !important;
    border-color: $col-primary-40 !important;
    :global(.MuiSelect-nativeInput) {
      opacity: 40 !important;
    }
  }
  :global(.MuiSelect-select) {
    color: white;
  }
}
:global(.MuiMenuItem-gutters) {
  font-family: $titillium !important;
}
::placeholder {
  color: white !important;
}

