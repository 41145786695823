@use 'styles/abstracts' as *;

.tariffSimulatorContainer {
  background-color: $col-primary-0;
  width: 100%;
  height: 100dvh;
  overflow: auto;
  padding: 1rem 1.5rem 1rem 2.25rem;
  :global(.Mui-expanded) {
    min-height: 40px !important;
  }
  :global(.MuiSelect-nativeInput:disabled) {
    -webkit-text-fill-color: transparent !important;
  }
  .vehicleForm {
    width: fit-content;
  }
  .datePickerContainer {
    display: grid;
    align-items: center;
    grid-template-columns: auto auto auto;
    svg {
      position: relative;
      right: 25px;
      pointer-events: none;
    }
  }
  :global(.react-datepicker-popper) {
    z-index: 2;
  }
  .datePicker,
  .react-datepicker__input-container,
  input {
    border-radius: 0.5rem;
    border: 1px solid $col-primary-60;
    height: 2.25rem;
    width: 9.6875rem;
    color: $col-white;
    font-size: 1rem;
    font-weight: 400;
    padding: 1rem;
    background-color: transparent;
    &:focus {
      border-color: $col-primary-90;
    }
    &:hover {
      background-color: $col-primary-10;
    }
  }
  ::placeholder {
    color: $col-primary-60 !important;
    font-family: $titillium;
  }
  textarea:focus,
  input:focus {
    outline: none;
  }
  .datePickerContainer {
    width: 50%;
    display: flex;
    flex-flow: row;
    gap: 1rem;
    label {
      color: $col-primary-100;
    }
  }
  .title {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    color: $col-primary-100;
  }
  .dateDate {
    display: flex;
  }
  .time,
  .date,
  .minutes {
    display: flex;
    flex-direction: column;
    label {
      color: $col-primary-100;
    }
  }
  .subtitle {
    color: $col-primary-100 !important;
  }
  .titleVehicle {
    color: $col-primary-100;
    margin-bottom: 0.5rem;
  }
  .simulatorBtn {
    display: flex;
    justify-content: flex-end;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

