@use 'styles/abstracts' as *;

.dateContainer {
  display: flex;
  :global(.react-datepicker-popper) {
    z-index: 2;
  }
  .pickerContainer {
    display: flex;
    flex-direction: column;
    label {
      margin-left: 0.5rem;
      color: $col-primary-100;
    }
    .datePicker,
    .react-datepicker__input-container,
    input {
      border-radius: 0.5rem;
      border: 1px solid $col-primary-60;
      height: 2.25rem;
      width: 9.6875rem;
      color: $col-white;
      font-size: 1rem;
      font-weight: 400;
      padding: 1rem;
      background-color: transparent;
      &:focus {
        border-color: $col-primary-90;
      }
      &:hover {
        background-color: $col-primary-10;
      }
    }
    ::placeholder {
      color: $col-primary-60 !important;
      font-family: $titillium;
    }
    & textarea:focus,
    input:focus {
      outline: none;
    }
    .datePickerContainer {
      display: grid;
      align-items: center;
      grid-template-columns: auto auto auto;
      svg {
        position: relative;
        right: 25px;
        pointer-events: none;
      }
    }
  }
}

.dateError {
  border: 1px solid $col-error-90 !important;
}
.labelError {
  color: $col-error-90 !important;
}

