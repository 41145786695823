@use 'styles/abstracts' as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .toTheRight {
    margin-left: auto;
  }
  .appliedTariffs {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    .appliedTariffsWrapper {
      display: flex;
      gap: 0.25rem;
      background-color: $col-primary-40;
      width: fit-content;
      border-radius: 0.5rem;
      .tariff {
        padding: 0.25rem;
        border-radius: 0.5rem;
        background-color: $col-primary-60;
        &.tariffTime {
          background-color: $col-primary-40;
        }
      }
    }
  }
  .subContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    &.total {
      margin-top: 2rem;
      font-size: large;
    }
  }
}

