@use 'styles/abstracts' as *;

.accordionContainer {
  height: auto;
  left: 84px;
  width: 100%;
  top: 302px;
}

.fieldsContainer {
  border-radius: 12px;
  background-color: $col-primary-10;
  height: fit-content;
  padding: 0.75rem 1rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 33.3%));
  margin-top: 0.5rem;
}

