@use 'styles/abstracts' as *;

.field {
  display: flex;
  flex-flow: column;
  gap: 1rem;
  padding-right: 0.5rem;
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.accordionContainer {
  padding: 0 0.5rem;
  :global(.MuiButtonBase-root) {
    padding-left: 0.5rem !important;
  }
  :global(.MuiAccordionDetails-root) {
    padding: 0 16px 0 !important;
  }
}

