@use 'styles/abstracts' as *;

.drop {
  width: 100%;
  height: 12.5rem;
  border-radius: 0.5rem;
  border: 1px dashed $col-primary-40;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:hover {
    background-color: $col-primary-0;
  }
  &.emptySelect {
    background-color: $col-primary-0;
    border: 1px solid $col-primary-20;
    span {
      color: $col-primary-50;
    }
  }
  .plusIcon {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  .openFile {
    color: $col-primary-80;
    cursor: pointer;
    text-decoration-line: underline;
  }
}
