@use 'styles/abstracts' as *;

.billingContainer {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  p {
    color: $col-primary-100;
  }
  label {
    margin-left: 0.5rem;
    color: $col-primary-100;
  }
}
