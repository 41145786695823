@use 'styles/abstracts' as *;

.userInfo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $col-primary-10;
  padding: 1rem 1.5rem;
  margin-bottom: 1.5rem;
  .deletedLabel {
    color: $col-primary-100;
    font-size: 1rem;
  }
  .info {
    display: flex;
    flex-flow: column;
    gap: 0.13rem;
    p {
      color: $col-white;
    }
    span {
      color: $col-primary-100;
    }
  }
  .profile {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    .status {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      b {
        color: $col-error-90;
      }
    }
  }
}

