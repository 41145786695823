@use 'styles/abstracts' as *;

.dividerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  span {
    height: 0.0625rem;
    width: 100%;
    margin: 1.25rem 0;
    background-color: $col-primary-40;
  }
}

