@use 'styles/abstracts' as *;

.weekModal {
  width: 304px;
  height: 150px;
}

.toggleGroupButton {
  gap: 1rem;
  margin-top: 0.5rem;
  :global(.MuiToggleButtonGroup-grouped:not(:last-of-type)) {
    color: $col-white;
    font-family: $titillium;
    width: 32px;
    height: 32px;
    border-radius: 50% !important;
    border: solid 1px transparent;
    &:global(.Mui-selected) {
      border-radius: 50% !important;
      border: solid 1px $col-primary-60;
      background-color: $col-primary-20 !important;
    }
  }
  :global(.MuiToggleButtonGroup-grouped:not(:first-of-type)) {
    color: $col-white;
    font-family: $titillium;
    width: 32px;
    height: 32px;
    border-radius: 50% !important;
    border: solid 1px transparent;
    border-left: solid 1px transparent !important;
    &:global(.Mui-selected) {
      border-radius: 50% !important;
      border: solid 1px $col-primary-60;
      background-color: $col-primary-20 !important;
      border-left: solid 1px $col-primary-60 !important;
    }
  }
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.customButton {
  width: 123px;
}
